const [d] = [document];

const domReady = () =>
{

};

export default function HOME () {
    d.addEventListener('DOMContentLoaded', domReady());
}
