const [w, d, l] = [window, document, location];

export default class Utility
{
    constructor() {
        this.init();
    }
    init() {
        //page top animation
        const $header = $('.l-header');
        const $totop_btn = $('.l-footer__totop');
        // $totop_btn.hide();
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $totop_btn.addClass('js-scroll');
                $header.addClass('js-scroll');
            } else {
                $totop_btn.removeClass('js-scroll');
                $header.removeClass('js-scroll');
            }
        });
        $totop_btn.on('click', function () {
            $('body,html').animate({ scrollTop: 0 }, 800);
            return false;
        });

        //external link
        $('a[href^="https://"],a[href^="http://"]').each(function () {
            const this_href = $(this).attr('href');
            const ignoreurl_reg = /(localhost|turnkey-service.jp)/; //除外したいドメインを|区切りで記述
            if (this_href !== undefined) {
                if (!this_href.match(ignoreurl_reg)) {
                    $(this).addClass('externalLink').attr('target', '_blank');
                }
            }
        });

        //アコーディオン
        $('.accordion-content').hide();
        $('.accordion-title').click(function () {
            $('.accordion-title').not(this).removeClass('open');
            $('.accordion-title').not(this).next().slideUp(300);
            $(this).toggleClass('open');
            $(this).next().slideToggle(300);
        });

        //スクロールして表示されたらクラス名付与
        $(window).on('lead scroll', function () {
            var elem = $('.effect-act');
            var isAnimate = 'is-scroll';
            elem.each(function () {
                var elemOffset = $(this).offset().top - 100;
                var scrollPos = $(window).scrollTop();
                var wh = $(window).height();

                if (scrollPos > elemOffset - wh + wh / 2) {
                    $(this).addClass(isAnimate);
                }
            });
        });

        $(window).on('load', function () {
            //$osusumeBtn.show();
        });

        //hamburger
        $(document).on(
            'click',
            '.l-header .hamburger,.is-open .l-header__navigation__list ._item a,.is-open .l-header__navigation ._inqLink',
            function () {
                $('body').toggleClass('modal_mode');
                $('.l-header .hamburger').toggleClass('is-active');
                $('.l-header__head').fadeToggle('fast').toggleClass('is-open');
            }
        );

        const ua = navigator.userAgent;
        const is_mobile =
            navigator.userAgent.indexOf('iPhone') > 0 ||
            navigator.userAgent.indexOf('Android') > 0;
        if (is_mobile) {
            //スマホで閲覧した時は電話番号にリンク追加
            $('.telNumber').each(function () {
                const tel_text = $(this).text().trim();
                if (!tel_text.match(/^[\d\-]+$/)) {
                    console.error(
                        tel_text,
                        ' : telNumberの中に半角数字とハイフン以外の文字が入っています。'
                    );
                    return false;
                }
                const tel_joined_number = tel_text.match(/\d/g).join('');
                $(this).wrapInner(
                    '<a href="tel:' + tel_joined_number + '"></a>'
                );
            });
        }

        const is_tablet =
            !is_mobile &&
            (ua.indexOf('iPad') > -1 ||
                (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) ||
                ua.indexOf('Android') > -1);
        //tabletだったらPC表示
        if (is_tablet) {
            $('head meta[name="viewport"]').attr('content', 'width=1300px');
        }
    }

    //URLによる読み込み分岐
    loader(loaderSwitch)
    {
        let path = location.pathname;
        loaderSwitch(path);
    }
}
