import Utility from "./class/utility";
import CUSTOMIZE from "./extended_class/customize";
import HOME from "./module/home";
import TMPL from "./module/tmpl";

// ユーティリティクラスのインスタンス化
const UTIL = new Utility();

// テンプレートインスタンス化（promise登録）
const TPL = new CUSTOMIZE();

// URLによる関数の実行
UTIL.loader( (path) => {
  switch( path ){
    case '/': HOME(); break
    case '/_test/_tmpl.php': TMPL(); break
  }
});

// テンプレートインスタンスの初期化（イベント発火）
TPL.init();